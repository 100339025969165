<template>
  <div class="rightSide">
    <div class="rightSide_inner">
      <div class="rightSide_inner_title">
        <p style="font-size: 30px; color: #333333;padding-left: 10px">
          {{$t("Display Sample Images") }}
          <span>  <el-button size="mini" round @click="$router.go(-1)">back</el-button> </span>
        </p>
      </div>
      <div class="content">
        <div class="images-list">
          <el-image :src="this.currentInfo.imagesSrc" style="width: 600px;height: 800px" fit="fill"
                    :preview-src-list=[this.currentInfo.imagesSrc]>
          </el-image>
        </div>
        <div class="recammend">
          <p style="font-size: 24px; color: #909399; margin-top: 10px">
            {{ $t("Related Products") }}
          </p>
          <div class="goods-list" v-for="(item,index) in recammendData" :key="index">
            <div class="goods-image">
              <el-image v-if="categoryType !== 1" :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
                        style="width: 120px; height: 120px" alt=""
                        @click="handlePreview(item.item_no)"
              :preview-src-list=srcList>
              </el-image>
              <el-image v-else :src="item.img" style="width: 120px; height: 120px" :preview-src-list=[item.img]></el-image>
            </div>
            <div class="goods-info">
              <p>{{item.item_en}}</p>
              <p>{{item.item_no}}
                <i style="padding-left: 5px" class="el-icon-document-copy"
                   v-clipboard:copy="item.item_no"
                   v-clipboard:success="onCopy"
                   v-clipboard:error="onError"></i>
              </p>
              <p>{{ $t("Purchase Price") }}:<span>￥{{ item.base_price }}</span></p>
              <p>{{ $t("Retail Price") }}:<span>￥{{ item.sale_price }}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "info",
  data() {
    return {
      categoryid: 0,
      imagesData: [],
      currentInfo:{},
      recammendData:[],
      srcList:[],
      categoryType:0
    };
  },
  created() {

    if (sessionStorage.getItem('currentImage') === null){
      this.$router.go(-1)
    }

    this.currentInfo = JSON.parse(sessionStorage.getItem('currentImage'))
    this.categoryType = parseInt(sessionStorage.getItem('categoryType'))
    console.log(this.categoryType);

    this.handleGetImages();

  },
  methods: {
    handleGetImages(){
      this.$axios.get('/w1/display/recammend',{
        params:{
          id:this.currentInfo.categoryid
        }
      }).then(res => {
        const {data} = res.data
        this.recammendData = data
      })
    },
    //复制商品条码
    onCopy: function() {
      this.$message.success("Product barcode copied to clipboard");
    },
    onError: function() {
      this.$message.success("Copy failed");
    },
    handlePreview(val){
      const url = `http://hwimg.xmvogue.com/thumb/${val}.jpg?x-oss-process=style/440`
      this.srcList = [url]
    }
  },
};
</script>

<style scoped lang="scss">
.rightSide {
  width: 1320px;
  margin: 15px 0 0 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  &_inner {
    width: 100%;
    height: 100%;

    &_title {
      width: 100%;
      height: 50px;
    }
  }
}

.table_style {
  width: 1218px;
  height: 68px;
  margin-top: 30px;
}

.footer {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content{
  .images-list {
    padding: 15px;
    float: left;
    .images {
      width: 320px;
      height: 460px;
      float: left;
      padding: 10px;
    }
    .images-title{
      text-align: center;
      padding: 10px;
    }
  }
  .recammend{
    float: left;
    width: 50%;
    padding-left: 20px;

    .goods-list{
      width: 100%;
      height: 160px;
      border: 1px solid #f5f8fa;
      padding: 5px 0;
      display: flex;
      align-items: center;
      .goods-image{
        float: left;
        width: 30%;
        text-align: center;
      }
      .goods-info{
        float: left;
        width: 70%;
        //text-align: center;
        p{
          line-height: 2;
          span{
            font-size: 16px; color: #d92524; margin-left: 5px
          }
        }
      }
    }
  }
}


</style>
